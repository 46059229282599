import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { ReportTypeValues } from "../routes/report_types";
import { BASE_ZINDEX } from "../../../popup-factory";
import {
  Button,
  colors,
  IconButton,
  Loading,
  Popup,
  PopupHeader,
  Row,
  Col,
  Text,
  XIcon,
} from "@commonsku/styles";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { closePopup, createDeleteReportPopup } from "../../../actions/popup";
import { formatDateFromString } from "../../../utils";
import { useContainerScroll } from "../../../hooks";
import { ScrollContainer } from "../../../hooks/useContainerScroll";
import { Report } from "./types";
import {
  fetchSavedReports,
  getSavedReportsState,
  loadSavedReport,
} from "../../../redux/savedReports";
import { getReportTypeTitle } from "../routes/helpers";

const StyledPopupHeader = styled(PopupHeader)`
  font-size: 24px;
  font-weight: 600;
  line-height: 40px;
  color: ${colors.neutrals["90"]};
`;

const StyledCloseButton = styled(XIcon)`
  cursor: pointer;
  font-size: 32px;
`;

const ReportItem = styled(Row)`
  width: 100%;
  height: 56px;
  font-size: 15px;
  line-height: 28px;
  color: ${colors.neutrals.darkest};
  margin-bottom: 8px;

  .report-name {
    font-weight: 600;
  }
`;

const LoadReportContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

interface PopupHeaderProps {
  title: React.ReactNode;
  onPopupClose: () => void;
}

export const PopupHeaderWithCloseIcon = ({
  title,
  onPopupClose,
}: PopupHeaderProps) => (
  <StyledPopupHeader>
    <Text>{title}</Text>
    <StyledCloseButton altText="Close" onClickCapture={onPopupClose} />
  </StyledPopupHeader>
);

interface LoadReportItemProps
  extends Pick<
    Report,
    "report_id" | "report_name" | "report_type_name" | "date_created"
  > {}

const LoadReportItem = ({
  report_name,
  report_id,
  report_type_name,
  date_created,
}: LoadReportItemProps) => {
  const dispatch = useDispatch();

  const loadReport = useCallback(() => {
    dispatch(loadSavedReport(report_id));
  }, [dispatch, report_id]);

  const deleteReport = useCallback(
    () => dispatch(createDeleteReportPopup(report_id)),
    [dispatch, report_id],
  );

  const reportType = useMemo(
    () => getReportTypeTitle(report_type_name as ReportTypeValues),
    [report_type_name],
  );

  const updatedOn = useMemo(
    () => `Updated on ${formatDateFromString(date_created)}`,
    [date_created],
  );

  return (
    <ReportItem>
      <Col xs={3}>
        <Text className="report-name">{report_name}</Text>
      </Col>
      <Col xs={3}>
        <Text>{reportType} Report</Text>
      </Col>
      <Col xs={3}>
        <Text>{updatedOn}</Text>
      </Col>
      <Col xs={3} flex end={1}>
        <Button size="medium" mr={8} onClick={loadReport}>
          Load
        </Button>
        <IconButton preset="delete" onClick={deleteReport} />
      </Col>
    </ReportItem>
  );
};

const LoadReportPopup = () => {
  const { loading, reports } = useSelector(getSavedReportsState);
  const dispatch = useDispatch();
  const onPopupClose = () => dispatch(closePopup());

  useEffect(() => {
    dispatch(fetchSavedReports());
  }, []);

  const ref = useRef<HTMLDivElement>(null);
  const { canScrollDown, canScrollUp, scrollDown, scrollUp } =
    useContainerScroll(ref);

  const renderReportItems = useCallback(
    () =>
      reports.length === 0 ? (
        <Text>No Reports Found</Text>
      ) : (
        reports
          .slice()
          .sort(
            (a, b) =>
              new Date(b.date_created).getTime() -
              new Date(a.date_created).getTime(),
          )
          .map((report: Report) => (
            <LoadReportItem
              key={report.report_id}
              report_name={report.report_name}
              report_type_name={report.report_type_name}
              report_id={report.report_id}
              date_created={report.date_created}
            />
          ))
      ),
    [reports],
  );

  const renderScrollContainers = useCallback(() => {
    if (!ref.current) {
      return null;
    }

    const { left, top, width, height, bottom } =
      ref.current.getBoundingClientRect();

    const scrollContainerProps = (isUp: boolean = true) => ({
      isUp,
      onClick: isUp ? scrollUp : scrollDown,
      canScroll: isUp ? canScrollUp() : canScrollDown(),
      color: colors.teal.main,
      width: width + 32,
      zIndex: 99999,
      top: isUp ? top - 20 : "auto",
      bottom: isUp ? "auto" : bottom - height - 88,
      left: left - 20,
      right: "auto",
      style: { cursor: "pointer" },
    });

    return (
      <>
        <ScrollContainer {...scrollContainerProps()} />
        <ScrollContainer {...scrollContainerProps(false)} />
      </>
    );
  }, [canScrollDown, canScrollUp, scrollDown, scrollUp]);

  return (
    <Popup
      closeOnEsc
      closeOnClickOutside={false}
      style={{ width: "70vw", height: 600 }}
      overlayZIndex={BASE_ZINDEX + 100}
      zIndex={BASE_ZINDEX + 200}
      header={
        <PopupHeaderWithCloseIcon
          title="Load Report"
          onPopupClose={onPopupClose}
        />
      }
      onClose={onPopupClose}
    >
      <LoadReportContainer ref={ref}>
        {loading && <Loading />}
        {!loading && renderReportItems()}
        {renderScrollContainers()}
      </LoadReportContainer>
    </Popup>
  );
};

export default LoadReportPopup;
